<template>
  <div v-if="isLoggedIn">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="container-fluid">
        Bella lì!<br>
        <h1>Moduli Abilitati</h1>
        <ul v-if="enabledModules.length > 0">
          <li v-for="module in enabledModules" :key="module.id_studio_module">
            {{ module.namefront }} <!-- Assicurati che id_module esista -->
          </li>
        </ul>
        <p v-else>Nessun modulo abilitato trovato.</p>
      </div>
      <AppFooter />
    </div>
  </div>
  <div v-else>
      <p>Devi effettuare il login per accedere a questa pagina.</p>
  </div>
</template>

<script>
//import { CIcon } from '@coreui/icons-vue';
import studioMixin from '@/mixins/studioMixin';
import AppHeader from '@/components/AppHeader.vue';  
import AppFooter from '@/components/AppFooter.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default {
  mixins: [studioMixin],
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
  },
  data() {
    return {
      isLoggedIn: false,
      enabledModules: [],
    };
  },
  mounted() {
    this.checkLogin();
    const studioId = this.getStudioIdFromToken();
    if (studioId) {
      //this.fetchStudioData(studioId);
      this.fetchEnabledModules();
    }
  },
  methods: {
    checkLogin() {
      const token = localStorage.getItem('token');
      this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
      if (!this.isLoggedIn) {
        this.$router.push('/login');
      }
    },
    async fetchEnabledModules() {
      //console.log('Token:', localStorage.getItem('token'));
      try {
        const response = await fetch('https://toothtime.it/api/modules/enabled', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          console.error('Risposta del server non OK:', errorMessage);
          throw new Error('Errore durante il recupero dei moduli abilitati.');
        }

        this.enabledModules = await response.json();
        console.log('Moduli abilitati caricati:', this.enabledModules);
      } catch (error) {
        console.error('Errore:', error);
      }
    }
  },
};
</script>
