<template>
    <div class="container vh-100 d-flex flex-column justify-content-center form-login">
        <div class="row">
            <div class="col-12">
                <img class="sidebar-brand-full" src="@/assets/logo_g.png" height="50"/>
                <h1>
                    Gazie per esserti unito a ToothTime!
                </h1>
                <h2>
                    Riceverai tanti vantaggi, tra cui l'accesso all'app in esclusiva!
                </h2>
                <br>
                <h3>
                    Seguici sui Social per essere aggiornato in tempo reale sulle novità di ToothTime
                </h3>
                <a href="https://www.instagram.com/toothtimeit">
                    <i class="fa-brands fa-2x fa-instagram" style="margin-right:20px;color:#000000;"></i>
                </a>
                <a href="https://www.facebook.com/ToothTimeIT">
                    <i class="fa-brands fa-2x fa-facebook" style="margin-right:20px;color:#000000;"></i> 
                </a>
                <a href="https://www.tiktok.com/@toothtimeit">
                    <i class="fa-brands fa-2x fa-tiktok" style="margin-right:20px;color:#000000;"></i> 
                </a>
                <a href="https://www.linkedin.com/showcase/toothtime/">
                    <i class="fa-brands fa-2x fa-linkedin" style="color:#000000;"></i>
                </a>
            </div>
        </div>
    </div>
</template>