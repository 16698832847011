<template>
    <div class="hero-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <img class="sidebar-brand-full" src="@/assets/logo_w.png" height="50"/>
                    <h1 class="hero-title">Privacy Policy</h1>
                </div>
            </div>
        </div>
    </div>

     <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div>
                    <h2>Informativa sul trattamento dei dati personali</h2>
                    <p>Questa informativa è resa ai sensi del D.lgs. 196/2003 – (Codice Privacy) – e del Regolamento UE 679/2016 (GDPR) e si applica esclusivamente ai dati raccolti attraverso il circuito ToothTime.</p>
                    <p>Nel dettaglio la presente informativa si applica alle seguenti applicazioni web:<br><strong><a href="https://toothtime.it/">ToothTime</a></strong><br>Tutti i servizi collegati ai domini sopra presenti e non elencati.</p>
                    <h3>Titolari del Trattamento dei Dati</h3>
                    <p><strong>CRAZY ADV S.R.L.</strong> di seguito “Crazy Adv” o “Titolare”,<br>con Sede Legale in:<br>Corso Alessandro Tassoni, 31/A<br>10043 – Torino (TO)<br>PEC: crazyadv@pec.it<br>REA TO-1288819<br>C.F./P.IVA 12422000013<br>Capitale Sociale € 10.000 i.v.<br></p>
                    <p><strong>Indirizzo email dei Titolari per la Privacy:</strong><br>privacy@crazyadv.com</p>
                    <p>Crazy Adv tratterà i dati personali sulla base del consenso dato per accedere ai servizi offerti da Crazy Adv e alle applicazioni web / siti sopra specificati, nonché sulla base dei legittimi interessi del Titolare quali a titolo esemplificativo e non esaustivo: messa in sicurezza e protezione del sito e/o applicazione web, degli utenti o di terzi da minacce per la sicurezza o frodi, l’osservanza delle leggi, lo svolgimento di attività amministrative, il miglioramento della propria attività e relazione con gli utenti.<br>Nel caso in cui il trattamento dei dati personali sia soggetto al consenso fornito, che sarà di volta in volta richiesto, l’utente e/o visitatore, avrà il diritto di revocarlo in qualsiasi momento e di opporsi al trattamento come indicato nel conseguimento della presente Privacy Policy.</p>
                    <h3>Tipologie di Dati raccolti</h3>
                    <p>Fra i Dati Personali raccolti dalle Applicazioni elencate, in modo autonomo o tramite terze parti, ci sono: Indirizzo e-mail, Nome, Cognome, Indirizzo IP e Cookie.</p>
                    <p>I primi dati, raccolti internamente, sono dati volontariamente da parte dell’utente al momento dell’invio di una richiesta tramite form/modulo contatti, durante l’iscrizione a una newsletter, landing page o funnel, durante l’invio di commenti o altri ed eventuali form/moduli presenti sul sito. Ulteriori dati potrebbero essere richiesti di volta in volta, e sempre forniti direttamente dall’utilizzatore del servizio.</p>
                    <h3>Dati di navigazione</h3>
                    <p>I sistemi informatici e i software che fanno funzionare questo sito acquisiscono, nel corso del loro normale funzionamento, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.</p>
                    <p>Questi dati non sono raccolti per essere associati ad interessati identificati, ma per loro natura potrebbero permettere di identificare gli utenti, attraverso elaborazioni ed associazioni con dati detenuti da terzi. Di per se, vengono raccolti anonimamente.</p>
                    <p>In questa categoria di dati rientrano gli indirizzi IP o i nomi di dominio dei computer utilizzati dagli utenti che si connettono al sito, i browser e i parametri del sistema informatico utilizzato per connettersi alle nostre applicazioni web, i dati di navigazione, compreso orario della richiesta e risposta ottenuta dal server.</p>
                    <p>Questi dati sono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso degli applicativi appartenenti al presente documento, per il miglioramento del servizio e per controllarne il corretto funzionamento.</p>
                    <h3>Cookies</h3>
                    <p>Le applicazioni elencate utilizzano i cookies. Dei file di testo temporanei o meno, registrati sul proprio computer e di varie tipologie.</p>
                    <p>Per maggiori informazioni ti riportiamo alla <a href="/cookie-policy/" target="_blank"><b>Cookie Policy</b></a>.</p>
                    <h3>Modalità e luogo del trattamento dei Dati raccolti</h3>
                    <p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.</p>
                    <p>Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di queste Applicazioni (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.</p>
                    <p>Potrai richiedere direttamente al Titolare in quale modo i tuoi dati siano stati usati, richiederne l’aggiornamento, la modifica o la cancellazione definitiva ove possibile.</p>
                    <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contattare il Titolare. I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.</p>
                    <h3>Finalità del trattamento dei Dati raccolto</h3>
                    <p>I dati personali saranno trattati da Crazy Adv per varie finalità:</p>
                    <ul>
                    <li>per attività di carattere amministrativo, contabile e funzionale all’adempimento di obblighi fiscali, contabili e di legge;</li>
                    <li>con il consenso dell’interessato, con finalità connesse alla registrazione a uno o più servizi offerti, e ogni informazione richiesta. Il rifiuto di questi dati potrà portare all’impossibilità di fornire i servizi richiesti. In ogni caso se questi dati vengono forniti, e viene confermato il consenso, verranno utilizzati per l’invio di comunicazioni, informazioni, aggiornamenti e per finalità di marketing presso Crazy Adv e/o i propri partner. Sempre mediante il consenso dell’interessato per una profilazione dello stesso. Con profilazione si intende qualsiasi forma di trattamento automatizzato dei dati personali, per valutare determinati aspetti personali, relativi ad eventuali analisi, o previsioni di situazioni economiche, preferenze personali, interessi o comportamento dell’utilizzo degli applicativi web. Il consenso rimane sempre facoltativo, ma in mancanza non sarà possibile completare la fornitura di servizi richiesti. Base giuridica del trattamento è il consenso dell’interessato.</li>
                    <li>sempre tramite il consenso dell’interessato, i dati potranno essere comunicati e/o ceduti (anche al di fuori dell’EU), a qualsiasi titolo, a terzi partners e soggetti selezionati da Crazy Adv, al fine di rispondere alle sue domande e/o esigenze, ricevere i loro messaggi informativi, le loro offerte nonché permetterle di partecipare ad eventi ed iniziative. Il consenso è facoltativo ma in mancanza di questo consenso non sarà possibile entrare in contatto con questi soggetti e farle inviare messaggi da parte loro e/o partecipare ad eventi ed iniziative per loro finalità commerciali e/o promozionali. Base giuridica del trattamento è il consenso dell’interessato.<br>Il conferimento dei dati necessari alle finalità di marketing, di profilazione e cessione a terzi è facoltativo e il trattamento richiede il consenso dell’interessato. L’eventuale rifiuto non avrà conseguenze sull’esecuzione dei servizi richiesti. Il consenso per finalità di marketing è richiesto per poter gestire la registrazione al sito ed inviare messaggi sulle iniziative e sui servizi di Crazy Adv.<br>I consensi espressi sono in ogni momento revocabili in modo agevole semplicemente scrivendo a Crazy Adv per segnalare questa volontà.<br>I dati non saranno diffusi e saranno utilizzati solo ai fini e con le modalità riportate.</li>
                    </ul>
                    <h3>Periodo di conservazione dei dati</h3>
                    <p>I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti. Nella maggior parte dei casi saranno trattenuti sino a quando sia completata l’esecuzione di un contratto legato all’acquisizione degli stessi dati.</p>
                    <h3>Trasferimento dei dati fuori del territorio UE (o dell’Unione Europea)</h3>
                    <p>I dati personali potranno essere trasferiti verso paesi dell’Unione Europea e verso paesi extra UE (inclusi gli USA). In tal caso, Crazy Adv assicura sin da ora che il trasferimento dei dati extra UE avverrà sulla base di adeguate garanzie, in conformità alle disposizioni di legge applicabili e che adotterà tutte le misure necessarie per garantire un adeguata protezione dei dati personali.</p>
                    <h3>Diritti dell’interessato</h3>
                    <p>In qualsiasi momento l’utente avrà la possibilità di esercitare i diritti previsti dagli articoli 15-22 del Regolamento, tra cui il diritto di:<br>a) accesso ai propri dati personali;<br>b) ottenere la conferma dell’esistenza o meno dei medesimi dati e di conoscerne il contenuto e l’origine;<br>c) ottenere la rettifica, l’aggiornamento o la cancellazione degli stessi;<br>d) opporsi al trattamento o di chiederne la limitazione;<br>e) chiedere la portabilità dei dati;<br>f) revocare il consenso in qualsiasi momento, ove previsto: la revoca del consenso non pregiudica la liceità del trattamento basata sul consenso prestato prima della revoca e di opporsi al trattamento per finalità di marketing e/o profilazione ai sensi dell’art. 21 del Regolamento;<br>g) proporre reclamo all’autorità di controllo (Garante Privacy).<br>Le richieste vanno rivolte al Titolare del trattamento CRAZY ADV S.R.L. con Sede Legale in Corso Alessandro Tassoni, 31/A – 10043 – Torino (TO)<br>O tramite email all’indirizzo privacy@crazyadv.com</p>
                    <h3>Maggiori dettagli sui Dati trattenuti e sui servizi di terze parti</h3>
                    <p><strong>Google Analytics</strong> con IP anonimizzato (Google Inc.)<br>Dati Personali raccolti: Cookie e Dati di utilizzo.<br>Luogo del trattamento: Stati Uniti – <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank" rel="noreferrer noopener">Opt Out</a>.</p>
                    <p><strong>Google Analytics</strong> (Google Inc.)<br>Dati Personali raccolti: Cookie e Dati di utilizzo.<br>Luogo del trattamento: Stati Uniti – <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank" rel="noreferrer noopener">Opt Out</a>.</p>
                    <p><strong>Facebook Pixel</strong> (Facebook, Inc.)<br>Dati Personali raccolti: Cookie e Dati di utilizzo.<br>Luogo del trattamento: Stati Uniti – <a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.</p>
                    <h3>Hosting ed Infrastruttura VPS e Backend</h3>
                    <p>Questo tipo di servizi ha la funzione di ospitare Dati e file che permettono a questo Sito Web di funzionare, ne consentono la distribuzione e mettono a disposizione un’infrastruttura pronta all’uso per erogare specifiche funzionalità di queste applicazioni web.</p>
                    <p>Alcuni di questi servizi funzionano attraverso server dislocati geograficamente in luoghi differenti, rendendo difficile la determinazione del luogo esatto in cui vengono conservati i Dati Personali.</p>
                    <p>Data Center Keliweb VPS e Servizio di Posta Elettronica – Italia – <a href="https://www.keliweb.it/gdpr-privacy-datacenter-italia.php" target="_blank" rel="noreferrer noopener">Privacy Policy</a></p>
                    <h3>Modifiche all’informativa sul trattamento dei dati personali, privacy policy e cookie policy</h3>
                    <p>La presente informativa, privacy policy e cookie policy, può subire modifiche nel tempo, anche connesse all’entrata in vigore di future nuove normative di settore, all’aggiornamento o erogazione di nuovi servizi. Per cui l’utilizzatore finale, sia esso utente e/o visitatore, è invitato a consultare periodicamente questa pagina. In caso di raccolta di dati personali sostanzialmente differenti o di cambiamenti delle relative finalità, la presente informativa verrà modificata e comunicata all’utente.</p>
                    <h3>Informazioni non contenute in questa informativa</h3>
                    <p>Puoi richiedere, in qualunque momento, ulteriori informazioni in relazione al trattamento dei dati personali contattandomi via mail, ti risponderemo nel più breve tempo possibile.</p>
                    <p>Data ultima modifica: 24-11-2024</p>
                </div>
            </div>
        </div>
    </div>
</template>