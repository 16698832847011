<template>
    <ion-page>
        <!-- Hero Section -->
        <div class="hero-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <img class="sidebar-brand-full" src="@/assets/logo_w.png" height="50"/>
                        <h1 class="hero-title">
                            ToothTime sta arrivando
                        </h1>
                        <p class="hero-subtitle">
                            Il 16 gennaio 2025, i primi 100 dentisti avranno l'opportunità di vedere in anteprima a ToothTime.<br>
                            Iscrivi ora, per prenotare il tuo posto, e partecipare all'evento online.
                        </p>
                        <p class="hero-subtitle">
                            Inoltre, iscrivendoti ora riceverai:<br>
                            L'ebook "La guerra delle ferie è finita: come trasformare il caos delle ferie in uno studio odontoiatrico efficiente e produttivo (senza impazzire)"
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Form Section -->
        <div class="container mt-5">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form action="https://app.getresponse.com/add_subscriber.html" class="form-section" accept-charset="utf-8" method="post">
                        <div class="mb-3">
                            <label for="name" class="form-label">NOME</label>
                            <input type="text" name="name" class="form-control" id="name" placeholder="Inserisci il tuo nome" required>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">EMAIL</label>
                            <input type="email" name="email" class="form-control" id="email" placeholder="Es. nome@example.com" required>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="true" id="webform_consent#GDGs_0" name="webform[consent#GDGs-ver#QeBx]" required>
                            <label class="form-check-label" for="webform_consent#GDGs_0">
                                Acconsento al trattamento dei dati personali e confermo di aver preso visione dell'
                                <a href="https://toothtime.it/privacy-policy" target="_blank" rel="nofollow noopener">informativa privacy</a>
                            </label>
                        </div>
                        <input type="hidden" name="campaign_token" value="jRHUo" />
                        <input type="hidden" name="thankyou_url" value="https://toothtime.it/thankyou"/>
                        <button type="submit" class="cta-btn w-100">OTTIENI IL TUO ACCESSO</button>
                    </form>
                </div>
            </div>
        </div>

        <!-- Description Section -->
        <div class="container mt-5">
            <div class="row justify-content-center text-center">
                <div class="col-md-6">
                    <p>
                        Con ToothTime <strong>presto potrai tracciare le ore dei tuoi dipendenti, ferie e straordinari tramite un'unica app.</strong>
                    </p>
                    <p>
                        <strong>Risparmia fino a 10 ore al mese</strong> grazie a un'organizzazione automatizzata, così potrai dedicarti ai tuoi pazienti o goderti il tuo tempo libero.<br>
                        Non aver paura di perdere informazioni o dettagli che riguardano la gestione delle risorse e del tempo del tuo studio dentistico.
                    </p>
                    <p>
                        <strong>Ti basterà ToothTime per essere sempre aggiornato su ciò che succede nel tuo studio dentistico.</strong>
                    </p>
                    <p>
                        <strong>Iscriviti ora per rimanere aggiornato e ricevere il tuo ebook.</strong>
                    </p>
                    <p>
                        <strong>La rivoluzione nella gestione delle risorse umane degli studi dentistici sta arrivando.</strong>
                    </p>
                </div>
            </div>
        </div>
    </ion-page>
</template>

<style>
.hero-section {
    background: linear-gradient(135deg, var(--primary-color), var(--step-color));
    color: white;
    padding: 80px 0;
    text-align: center;
}
.hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 1rem;
}
.hero-subtitle {
    font-size: 1.25rem;
    margin-bottom: 30px;
}
.cta-btn {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: 1px solid;
    border-color: var(--black-color);
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 5px;
    margin-top: 1rem;
}
.cta-btn:hover {
    background-color: var(--step-color);
}
.form-section {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.form-title {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 30px;
}
.form-control {
    border-radius: 5px;
}
</style>