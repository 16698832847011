<template>
  <div v-if="isLoggedIn">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="container-fluid">
          <h1>Configurazione Studio</h1>
          <form @submit.prevent="submitForm">
              <div class="row">
                  <div class="col-md-6 col-sm-12 mb-3">
                      <label for="name" class="form-label">Nome Studio</label>
                      <input type="text" v-model="formData.name" class="form-control" id="name" required />
                  </div>
                  <div class="col-md-6 col-sm-12 mb-3">
                      <label for="url" class="form-label">URL del Logo</label>
                      <input type="text" v-model="formData.url" class="form-control" id="url" required />
                  </div>
                  <div class="col-4 mb-3">
                      <label for="baseColor" class="form-label">Colore Base</label>
                      <input type="color" v-model="formData.baseColor" class="form-control" id="baseColor" />
                  </div>
                  <div class="col-4 mb-3">
                      <label for="secondaryColor" class="form-label">Colore Secondario</label>
                      <input type="color" v-model="formData.secondaryColor" class="form-control" id="secondaryColor" />
                  </div>
                  <div class="col-4 mb-3">
                      <label for="textColor" class="form-label">Colore Testo</label>
                      <input type="color" v-model="formData.textColor" class="form-control" id="textColor" />
                  </div>
                  <div class="col-6 mb-3">
                      <label for="font" class="form-label">Font Disponibili</label>
                      <select v-model="formData.fontId" class="form-select" id="font">
                      <option v-for="font in fonts" :key="font.id" :value="font.id">{{ font.name }}</option>
                      </select>
                  </div>
                  <div class="col-6 mb-3">
                      <label for="background" class="form-label">Sfondi Disponibili</label>
                      <select v-model="formData.backgroundId" class="form-select" id="background">
                      <option v-for="image in backgroundImages" :key="image.id" :value="image.id">{{ image.name }}</option>
                      </select>
                  </div>
                  <input type="hidden" v-model="formData.id_studio" />
                  <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-primary btn-lg w-100">Salva Configurazione</button>
                  </div>
              </div>
          </form>
      </div>
      <AppFooter />
    </div>
  </div>
  <div v-else>
      <p>Devi effettuare il login per accedere a questa pagina.</p>
  </div>
</template>

<script>
//import { CIcon } from '@coreui/icons-vue';
import studioMixin from '@/mixins/studioMixin';
import AppHeader from '@/components/AppHeader.vue';  
import AppFooter from '@/components/AppFooter.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default {
  mixins: [studioMixin],
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
  },
  data() {
    return {
      isLoggedIn: false,
      formData: {
          name: '',
          url: '',
          configuration: {
              baseColor: '#ffffff',
              secondaryColor: '#000000',
              textColor: '#333333',
              font: '',
              backgroundImage: '',
          },
      },
      fonts: [
          { id: '1', name: 'Arial' },
          { id: '2', name: 'Helvetica' },
          { id: '3', name: 'Times New Roman' },
      ],
      backgroundImages: [
          { id: '1', name: 'Background 1' },
          { id: '2', name: 'Background 2' },
          { id: '3', name: 'Background 3' },
      ],
      studioId: null,
    };
  },
  mounted() {
    this.checkLogin();
    const studioId = this.getStudioIdFromToken();
    if (studioId) {
      this.fetchStudioData(studioId);
    }
  },
  methods: {
    checkLogin() {
      const token = localStorage.getItem('token');
      this.isLoggedIn = !!token;
      if (!this.isLoggedIn) {
        this.$router.push('/login');
      }
    },
    async fetchStudioData() {
      const studioId = this.getStudioIdFromToken();
      if(studioId){
        try {
          //console.log('Recupero dati per studio ID:', studioId);
          const response = await fetch(`https://toothtime.it/api/studio/${studioId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Errore durante il recupero dei dati dello studio.');
          }

          const studioData = await response.json();
          //console.log('Dati dello studio recuperati:', studioData);
          this.formData.id_studio = studioData.id_studio;
          this.formData.name = studioData.name;
          this.formData.url = studioData.logo;
          if (studioData.configuration) {
            const config = typeof studioData.configuration === 'string' ? JSON.parse(JSON.parse(studioData.configuration)) : studioData.configuration;
            //const config = JSON.parse(JSON.parse(studioData.configuration));
            //console.log('Configurazione dopo il parsing:', config);
            //console.log('Tipo di config:', typeof config);
            this.formData.baseColor = config.baseColor || '#ffffff'; // Valore di default
            this.formData.secondaryColor = config.secondaryColor || '#000000'; // Valore di default
            this.formData.textColor = config.textColor || '#333333'; // Valore di default
            this.formData.fontId = config.font || '1'; // ID del font
            this.formData.backgroundId = config.backgroundImage || '1'; // ID dell'immagine di sfondo
          }
        } catch (error) {
          console.error('Errore:', error);
        }
      }
    },
    async submitForm() {
        await this.saveStudio(); // Chiama il metodo per salvare lo studio
    },
    async saveStudio() {
        try {
          const url = this.formData.id_studio ? `https://toothtime.it/api/studio/${this.formData.id_studio}` : 'https://toothtime.it/api/studio';
          const method = this.formData.id_studio ? 'PUT' : 'POST';

          //console.log(this.formData.fontId);

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: this.formData.name,
                    url: this.formData.url,
                    configuration: JSON.stringify({
                        baseColor: this.formData.baseColor,
                        secondaryColor: this.formData.secondaryColor,
                        textColor: this.formData.textColor,
                        font: this.formData.fontId, // Assicurati che i nomi corrispondano
                        backgroundImage: this.formData.backgroundId, // Assicurati che i nomi corrispondano
                    }),
                }),
            });

            //const responseData = await response.text();

            if (!response.ok) {
              //console.error('Risposta del server non OK:', responseData);
              throw new Error('Errore durante il salvataggio dello studio.');
            }

            const data = await response.json();
            console.log('Studio creato con ID:', data.studioId);
            // Potresti reindirizzare l'utente a un'altra pagina o mostrare un messaggio di successo
        } catch (error) {
            console.error('Errore:', error);
        }
    },
  },
};
</script>
