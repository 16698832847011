<template>
    <div class="hero-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <img class="sidebar-brand-full" src="@/assets/logo_w.png" height="50"/>
                    <h1 class="hero-title">Cookie Policy</h1>
                </div>
            </div>
        </div>
    </div>

     <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div>
                    <p>La presente Cookie Policy si applica alle seguenti applicazioni web:<br><strong><a href="https://www.toothtime.it/">ToothTime</a></strong></p>
                    <h3>Cosa sono i Cookie?</h3>
                    <p>I Cookie sono dei file di testo temporanei o meno, registrati sul proprio computer e di varie tipologie.</p>
                    <p>Vengono utilizzati per migliorare l’esperienza lato utente, e sono inviati dai browser (es. Google Chrome, Mozilla Firefox…), vengono memorizzati localmente e inviati indietro all’applicazione per la verifica e il controllo di alcune informazioni. Ma il loro utilizzo non è solo questo, infatti vengono suddivisi in varie categorie.</p>
                    <h3>Come si dividono i Cookie?</h3>
                    <p><strong>Cookie di sessione/tecnici:</strong> non sono strumentali alla raccolta di dati personali con cui identificare l’utente e durano per la sola sessione in corso, cioè fino alla chiusura del browser. Il loro uso non richiede il tuo consenso. Utilizzando il sito acconsenti espressamente all’uso di questi cookie.</p>
                    <p><strong>Cookie statistici: </strong>sono utilizzati per raccogliere informazioni, in forma aggregata, sul numero degli visitatori e su come questi navigano questo sito. Il servizio fa parte della gestione esterna dei Cookie legata a Google Analytics. Per maggiori informazioni: <a href="https://policies.google.com/?hl=it" target="_blank" rel="noreferrer noopener"><strong>Privacy</strong> e <strong>Cookie Policy</strong> di <strong>Google</strong></a></p>
                    <p><strong>Cookie di terze parti</strong> (servizi esterni): servono per permettere la condivisione dei contenuti di questo sito su alcuni social network, per finalità statistiche o di marketing. Se decidi di interagire con le applicazioni web alcune informazioni personali potrebbero essere acquisite dai gestori delle piattaforme dei social network o di altri network. Noi non abbiamo accesso diretto ai dati che sono autonomamente raccolti e trattati dai gestori delle piattaforme dei social network o altri network.</p>
                    <p>Alcuni Cookie di terze parti sono i Cookie legati alle attività Social e ai pulsanti di condivisione:</p>
                    <p><strong>Facebook</strong>: <a href="https://www.facebook.com/policies/cookies/" target="_blank" rel="noreferrer noopener nofollow">Cookie Policy</a></p>
                    <p><strong>Instagram</strong>:  <a href="https://help.instagram.com/1896641480634370" target="_blank" rel="noreferrer noopener nofollow">Cookie Policy</a> </p>
                    <p><strong>LinkedIn</strong>:  <a href="https://www.linkedin.com/legal/cookie-policy" target="_blank" rel="noreferrer noopener nofollow">Cookie Policy</a> </p>
                    <p><strong>Google e YouTube</strong>: <a href="https://policies.google.com/technologies/cookies?hl=it" target="_blank" rel="noreferrer noopener nofollow">Cookie Policy</a></p>
                    <p><strong>Cookie di profilazione: </strong>sono utilizzati per la creazione di un “profilo” di ogni utente. Questo profilo viene generato in base alle preferenze da lui manifestate durante la navigazione web. Il suo scopo è mostrare annunci pubblicitari coerenti con le proprie preferenze. Noi utilizziamo questo tipo di Cookie per tracciare i gusti dei nostri utenti, al fine di mostrare solamente annunci pubblicitari coerenti con i loro interessi.</p>
                    <p><strong>Facebook Pixel</strong>: <a href="https://www.facebook.com/policies/cookies/" target="_blank" rel="noreferrer noopener nofollow">Cookie Policy</a> – <a href="https://www.facebook.com/ads/settings" target="_blank" rel="noreferrer noopener nofollow">Modifica le tue preferenze</a></p>
                    <p><strong>Google Pixel</strong>:  <a href="https://www.google.com/settings/ads/onweb/" target="_blank" rel="noreferrer noopener nofollow">Modifica le tue preferenze</a> </p>
                    <h3>Come posso eliminare o disattivare i Cookie?</h3>
                    <p>Ad eccezione dei Cookie di sessione (detti anche Cookie tecnici), che sono indispensabili per la corretta fruizione del servizio, l’utente potrà decidere in completa autonomia quali Cookie utilizzare, la loro eventuale disattivazione o completa eliminazione.</p>
                    <p>Ogni browser web ha diverse procedure per il settaggio e la rimozione di queste informazioni.</p>
                    <p>Puoi cancellare tutti i cookie che si trovano sul tuo dispositivo eliminando la cronologia di navigazione del tuo browser: così facendo cancellerai tutti i cookie di tutti i siti visitati.</p>
                    <p>Tieni però presente che potresti anche perdere alcune informazioni che avevi salvato (ad esempio le credenziali di accesso o le preferenze per il sito).</p>
                    <p>Per un controllo più dettagliato dei cookie in base al sito di provenienza, verifica le impostazioni sulla privacy e sui cookie nel tuo browser preferito.</p>
                    <p>La maggior parte dei browser moderni consente all’utente di impedire la creazione di cookie sul proprio dispositivo, ma in tal caso sarà necessario adattare manualmente alcune preferenze ogni volta che si visita un sito o una pagina. Inoltre, alcuni servizi e funzionalità potrebbero non funzionare correttamente (ad esempio la connessione con un profilo).</p>
                    <p>Data ultima modifica: 24-11-2024</p>
                </div>
            </div>
        </div>
    </div>
</template>