<template>
<div v-if="isLoggedIn">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
    <AppHeader />
    <div class="container-fluid">
        <div class="row text-center mb-3">
            <div class="col-md-12">
                <h1>Timbra timbratoooore</h1>
                <h2>Clicca, clicca, cliccaaaaaa..</h2>
                <!--<div v-if="isMobile">aaa</div><div v-else>bbb</div>-->
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center flex-wrap">
                    <!--
                    <button type="button" class="btn btn-badge btn-primary" @click="setBadgeType(1)">Ingresso</button>
                    <button type="button" class="btn btn-badge btn-danger" @click="setBadgeType(2)">Uscita</button>
                    -->
                    <button 
                        v-if="lastAction !== 1" 
                        type="button" 
                        class="btn btn-badge btn-primary" 
                        @click="setBadgeType(1)">Ingresso
                    </button>
                    <button 
                        v-if="lastAction !== 2" 
                        type="button" 
                        class="btn btn-badge btn-danger" 
                        @click="setBadgeType(2)">Uscita
                    </button>
                    <button type="button" class="btn btn-badge btn-warning" @click="setBadgeType(3)">Pausa</button>
                </div>
                <div v-if="message" :class="'alert alert-' + alerttype + ' mt-3'">{{ message }}</div>
            </div>
        </div>
    </div>
    <AppFooter />
    </div>
</div>
<div v-else>
    <p>Devi effettuare il login per accedere a questa pagina.</p>
</div>
</template>

<script>
//import { CIcon } from '@coreui/icons-vue';
import { Device } from '@capacitor/device';
import AppHeader from '@/components/AppHeader.vue';  
import AppFooter from '@/components/AppFooter.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default {
    components: {
        AppHeader,
        AppFooter,
        AppSidebar,
    },
    data() {
        return {
            isLoggedIn: false,
            isMobile: false,
            badgeData: {
                typeaction: null,  // Tipo di timbratura (1 = Entrata, 2 = Uscita, 3 = Pausa)
            },
            message: '',
            alerttype: '',
            lastAction: null,
        };
    },
    async created() {
        const info = await Device.getInfo();
        this.isMobile = info.platform === 'ios' || info.platform === 'android';
        this.fetchLastTimbratura();
    },
    mounted() {
        this.checkLogin();
    },
    methods: {
        checkLogin() {
            const token = localStorage.getItem('token');
            this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
            if (!this.isLoggedIn) {
                this.$router.push('/login');
            }
        },
        fetchLastTimbratura() {
            // Recupera l'ultima timbratura dall'API
            fetch('https://toothtime.it/api/ultima-timbratura', {
                headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
            .then(response => response.json())
            .then(data => {
                this.lastAction = data.action;  // 'entrata', 'uscita', o null
            })
            .catch(error => console.error('Errore nel recupero dell\'ultima timbratura:', error));
        },
        setBadgeType(typeaction) {
            this.badgeData.typeaction = typeaction;
            this.alerttype = 'success';
            if (typeaction === 1) {
                this.message = 'Timbratura di ingresso registrata!';
            } else if (typeaction === 2) {
                this.message = 'Timbratura di uscita registrata!';
            } else if (typeaction === 3) {
                this.message = 'Pausa registrata!';
            }
            this.submitBadge();
        },
        async submitBadge() {
            if (!this.badgeData.typeaction) {
                this.message = 'Seleziona una timbratura!';
                return;
            }
            try {
                const url = 'https://toothtime.it/api/timbratura';
                const method = 'POST';

                const response = await fetch(url, {
                    method: method,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        typeaction: this.badgeData.typeaction,
                    }),
                });

                if (!response.ok) {
                //console.error('Risposta del server non OK:', responseData);
                throw new Error('Errore durante il salvataggio dello studio.');
                }
                //const data = await response.json();
                window.location.reload();

            } catch (error) {
                console.error('Errore:', error);
                this.alerttype = 'danger';
                this.message = 'Si è verificato un errore. Riprova.';
            }
        }
    }
};
</script>