import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import { CBadge, CSidebarNav, CNavItem, CNavGroup, CNavTitle } from '@coreui/vue'
import nav from '@/_nav.js'

import simplebar from 'simplebar-vue'
import 'simplebar-vue/dist/simplebar.min.css'

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute()
    const firstRender = ref(true)
    const enabledModules = ref([]);

    onMounted(async () => {
      firstRender.value = false
      await fetchEnabledModules()
    })

    const fetchEnabledModules = async () => {
      try {
        const response = await fetch('https://toothtime.it/api/modules/enabled', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Errore durante il recupero dei moduli abilitati.')
        }

        enabledModules.value = await response.json();
        console.log('Moduli abilitati:', enabledModules.value);

        enabledModules.value.forEach(module => {
          console.log("Modulo "+module.id_module)
          switch (module.id_module) {
            /*
            case 1:
              nav[4].items.push({
                component: 'CNavItem',
                name: 'Dashboard',
                to: '/dashboard',
                icon: 'cil-speedometer',
              });
              break;
            */
            case 2:
                nav[4].items.push({
                  component: 'CNavItem',
                  name: 'Bacheca',
                  to: '/module/studioboard',
                  icon: 'cil-clipboard',
                });
                break;
            case 3:
              nav[4].items.push({
                component: 'CNavItem',
                name: 'Timbrature',
                to: '/module/badge',
                icon: 'cil-history',
                badge: {
                  color: 'primary',
                  text: 'NEW',
                },
              });
              break;
            case 4:
              nav[4].items.push({
                component: 'CNavItem',
                name: 'Calendario',
                to: '/module/calendar',
                icon: 'cil-calendar',
              });
              break;
            case 5:
              nav[4].items.push({
                component: 'CNavItem',
                name: 'Cassetto Docs',
                to: '/module/drawerdocs',
                icon: 'cil-spreadsheet',
              });
              break;
            case 6:
              nav[4].items.push({
                component: 'CNavItem',
                name: 'Formazione',
                to: '/module/training',
                icon: 'cil-education',
              });
              break;
            default:
              break;
          }
        });

      } catch (error) {
        console.error('Errore:', error);
      }
    }

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            as: 'div',
            compact: true,
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: 'nav-icon',
                name: item.icon,
              }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }

      return item.to
      ? h(
          RouterLink,
          {
            to: item.to,
            custom: true,
          },
          {
            default: (props) =>
              h(
                resolveComponent(item.component),
                {
                  active: props.isActive,
                  as: 'div',
                  href: props.href,
                  onClick: () => props.navigate(),
                },
                {
                  default: () => [
                    item.icon
                      ? h(resolveComponent('CIcon'), {
                          customClassName: 'nav-icon',
                          name: item.icon,
                        })
                      : h('span', { class: 'nav-icon' }, h('span', { class: 'nav-icon-bullet' })),
                    item.name,
                    item.badge &&
                      h(
                        CBadge,
                        {
                          class: 'ms-auto',
                          color: item.badge.color,
                        },
                        {
                          default: () => item.badge.text,
                        },
                      ),
                  ],
                },
              ),
          },
        )
      : h(
          resolveComponent(item.component),
          {
            as: 'div',
          },
          {
            default: () => item.name,
          },
        )
    }

    return () =>
      h(
        CSidebarNav,
        {
          as: simplebar,
        },
        {
          default: () => nav.map((item) => renderItem(item)),
        },
      )
  },
})

export { AppSidebarNav }