import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import StudioConfig from '@/views/studio/StudioConfig.vue';
import Badge from '@/views/modules/BadgeModule.vue';
import Squeeze from '@/views/webpages/SqueezePage.vue';
import Tnku from '@/views/webpages/ThankyouPage.vue';
import Privacy from '@/views/webpages/PrivacyPage.vue';
import Cookie from '@/views/webpages/CookiePage.vue';

const routes = [
  { path: '/login', component: LoginPage },
  { path: '/dashboard', component: DashboardPage, meta: { requiresAuth: true } },
  { path: '/theme/config', component: StudioConfig, meta: { requiresAuth: true } },
  { path: '/module/badge', component: Badge, meta: { requiresAuth: true } },
  { path: '/', redirect: '/login' },
  //{ path: '/top50', component: Squeeze },
  { path: '/lista-attesa', component: Squeeze },
  { path: '/thankyou', component: Tnku },
  { path: '/privacy-policy', component: Privacy },
  { path: '/cookie-policy', component: Cookie },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Middleware per la protezione delle rotte
router.beforeEach((to, from, next) => {
  //console.log('Tentativo di accesso a:', to.path);
  if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    console.log('Accesso NON autorizzato a:', to.path);
    next('/login');
  } else {
    console.log('Accesso autorizzato a:', to.path);
    next();
  }
});

export default router;
