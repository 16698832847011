import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router/router';

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
//import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/custom.css';

createApp(App)
    .use(IonicVue)
    .use(createPinia())
    .use(router)
    .use(CoreuiVue)
    .provide('icons', icons)
    .component('CIcon', CIcon)
    .mount('#app')
