<template>
    <div class="container vh-100 d-flex flex-column justify-content-center form-login">
      <div class="row">
        <div class="col-12">
          <img class="img-fluid" src="../assets/logo_g.png"/>
          <form @submit.prevent="login">
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input type="email" v-model="email" class="form-control" id="email" required />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input type="password" v-model="password" class="form-control" id="password" required />
            </div>
            <div class="d-grid gap-2">
              <button type="submit" class="btn btn-primary btn-lg">Accedi</button>
            </div>
          </form>
          <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        error: null,
      };
    },
    methods: {
      async login() {
        try {
          const response = await fetch('https://toothtime.it/api/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: this.email, password: this.password }),
          });
  
          if (!response.ok) {
            //throw new Error('Login fallito: ' + response.statusText);
            throw new Error('Login fallito.');
          }
  
          const data = await response.json();
          console.log('Token ricevuto:', data.token);
          localStorage.setItem('token', data.token);
          this.$router.push('/dashboard');
        } catch (error) {
          this.error = error.message;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 400px;
    margin: auto;
    padding-top: 50px;
  }
  </style>
  