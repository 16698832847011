export default [
    {
      component: 'CNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-speedometer',
    },
    {
      component: 'CNavTitle',
      name: 'Configurazioni',
    },
    {
      component: 'CNavItem',
      name: 'Stile App',
      to: '/theme/config',
      icon: 'cil-drop',
    },
    {
      component: 'CNavTitle',
      name: 'Gestione',
    },
    {
      component: 'CNavGroup',
      name: 'Moduli Attivi',
      to: '/base',
      icon: 'cil-puzzle',
      items: [],
    },
  ]
  