<template>

    <CFooter class="px-4 nascosto">
      <div class="mr-auto">
        <span class="ms-1">
          Powered with ❤️‍🔥 by 
          <a href="https://www.crazyadv.com/" target="_blank">Crazy Adv Srl</a>
        </span>
        <span class="ms-1">
          &copy; {{ new Date().getFullYear() }} ToothTime v 0.0.2
        </span>
      </div>
    </CFooter>

    <div v-if="isMobile">
      <ion-content class="ion-padding"></ion-content>
      <ion-footer class="ion-no-border">
        <ion-toolbar>
          <ion-button href="/module/badge" expand="full">Timbra</ion-button>
        </ion-toolbar>
      </ion-footer>
    </div>
    
  </template>
  
<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
  ion-button {
    --background: #97AF8F;
    --background-hover: #B0C2AB;
    --background-activated: #B0C2AB;
    --background-focused: #B0C2AB;

    --color: #1B1B1B;
  }
</style>