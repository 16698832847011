//import { jwtDecode } from "jwt-decode";
import jwt_decode from 'jwt-decode';

export default {
  methods: {
    getStudioIdFromToken() {
      const token = localStorage.getItem('token');
      if (!token) return null;
        //console.log(token);
        try {
        const decoded = jwt_decode(token);
        console.log('Dati decodificati:', decoded);
        return decoded.id_studio;
        } catch (error) {
        console.error('Errore nella decodifica del token:', error);
        return null;
        }
    },
  },
};